<template>
<div class="story-wrap">
    <h3>
      <a class="black" :href="link" :data-track="`story-${kabobTitle}`" target="_blank" @click="clickTrack">
        {{ title }}
      </a>
    </h3>
    <p class="venue">
      {{ venue }}
    </p>
</div>
</template>

<script>
export default {
  props: {
    title: String,
    venue: String,
    link: String,
    dataTrack: String,
  },
  methods: {
    clickTrack($e) {
      var target = $e.target;
      if (target.getAttribute("target") == "_blank") {
        $e.preventDefault();
      }
      var href = target.getAttribute("href")
      var trackData = target.getAttribute("data-track")
      // console.log("click ", href, trackData)
      if (trackData) {
        this.$gtag.event('click_' + trackData, { method: 'Google' })
      }
      if (target.getAttribute("target") == "_blank") {
        window.open(href);
      }
    }
  },
  computed: {
    // a computed getter
    kabobTitle() {
      // `this` points to the component instance
      let kabobTitle = this.title.toLowerCase();
      kabobTitle = kabobTitle.replaceAll(" ", "-");
      kabobTitle = kabobTitle.replaceAll(",", "");
      kabobTitle = kabobTitle.replaceAll("'", "");
      kabobTitle = kabobTitle.replaceAll("?", "");
      kabobTitle = kabobTitle.replaceAll("&", "");
      return kabobTitle;
    }
  }
}
</script>

<style lang="scss">
.story-wrap {
  a h3,
  a p {
    text-decoration: none;
  }

  p.venue {
    font-style: italic;
  }
}
</style>