<template>
  <div class="page">
    <section>
      <div class="max-w-wrap">
        <h2>
          Are you like a writer or&nbsp;something?
        </h2>
        <p>
          K.A. Honeywell (she/her) has written <a href="/publications/">short stories and books</a>. She lives in the Pacific Northwest. 
        </p>
        <p>
          She can be contacted at <a href="mailto:kahoneywell@gmail.com">kahoneywell@gmail.com</a>. You can sign up for her newsletter&nbsp;below to get updates on new stories and books, and that's it. No spamming your inbox.
        </p>
        <p>&nbsp;</p>
        <iframe src="https://kahoneywell.substack.com/embed" width="330" height="300" frameborder="0" scrolling="no"></iframe>
      </div>
    </section>
    <section>
      <div class="max-w-wrap">
        <div class="two-up books">
          <BookInfo size="small" title="Veiled Scarlet" descShort="Confined to a maze-like manor, the Countess’s daughter and her servant question whether the house is keeping them safe or hiding who they really&nbsp;are." folder="veiled-scarlet" cover="cover-667x1000.jpg" />
          <BookInfo size="small" title="Damn Wilds" descShort="Fast-paced, modern western-fantasy with magic-users and gunslingers stealing cars and killing monsters." folder="damn-wilds" cover="cover-1000x1500.jpg" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BookInfo from '@/components/BookInfo.vue';
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    BookInfo,
  },
  setup () {
    useMeta({ title: 'About' })
  }
}
</script>

<style lang="scss">
.two-up {
  &.books {
    display: flex;
    gap: 150px;

    .book-wrap {
      width: 50%;
    }
  }

  @media screen and (max-width: 1024px) {
    &.books {
      flex-direction: column;
      gap: 50px;

      .book-wrap {
        width: 100%;
      }
    }
  }
}
</style>