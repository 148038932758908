import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Publications from "@/views/Publications.vue";
import DamnWilds from "@/views/publications/DamnWilds.vue";
import VeiledScarlet from "@/views/publications/VeiledScarlet.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'K.A. Honeywell',
      metaTags: [
        {
          property: 'og:title',
          content: 'K.A. Honeywell'
        },
        {
          name: 'description',
          content: 'Fiction by K.A. Honeywell'
        },
        {
          property: 'og:description',
          content: 'Fiction by K.A. Honeywell'
        },
        {
          name: 'keywords',
          content: 'speculative fiction, fantasy books, urban fantasy books, low fantasy books, damn wilds, magical western, magic road trip fiction, veiled scarlet, fairy tale books, queer fiction'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: 'https://www.kahoneywell.com/'
        },
        {
          property: 'og:site_name',
          content: 'K.A. Honeywell'
        },
        {
          property: 'og:image',
          content: 'https://www.kahoneywell.com/social-share.jpg'
        }
      ]
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/publications",
    name: "Publications",
    component: Publications,
  },
  {
    path: "/publications/damn-wilds/",
    name: "Damn Wilds",
    component: DamnWilds,
  },
  {
    path: "/publications/veiled-scarlet/",
    name: "Veiled Scarlet",
    component: VeiledScarlet,
    meta: {
      title: 'Veiled Scarlet by K.A. Honeywell',
      metaTags: [
        {
          property: 'og:title',
          content: 'K.A. Honeywell'
        },
        {
          name: 'description',
          content: 'Veiled Scarlet by K.A. Honeywell'
        },
        {
          property: 'og:description',
          content: 'Veiled Scarlet by K.A. Honeywell'
        },
        {
          name: 'keywords',
          content: 'speculative fiction, fairy tales, veiled scarlet, queer fiction, lesbian romance, sapphic books'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: 'https://www.kahoneywell.com/'
        },
        {
          property: 'og:site_name',
          content: 'K.A. Honeywell'
        },
        {
          property: 'og:image',
          content: 'https://www.kahoneywell.com/img/veiled-scarlet/social.png'
        }
      ]
    }
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;