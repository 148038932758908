<template>
  <nav>
    <div class="max-w-wrap content">
      <span class="name">
        <a href="/" data-track="nav-home"  @click="clickTrack">
          K.&thinsp;A. Honeywell
        </a>
      </span>
      <div class="pages-wrap">
        <span class="page-link"><a data-track="nav-publications" href="/publications/" @click="clickTrack">Publications</a></span>
        <span class="page-link"><a data-track="nav-about" href="/about/" @click="clickTrack">About</a></span>
      </div>
      <div class="list">
        <span class="item">
          <a data-track="twitter" href="https://twitter.com/kahoneywell" target="_blank" @click="clickTrack">
            <img class="icon tw" src="../assets/img/icon-tw.svg" alt="Twitter" />
          </a>
        </span>
        <span class="item">
          <a data-track="instagram" href="https://instagram.com/kahoneywell" target="_blank" @click="clickTrack">
            <img class="icon tw" src="../assets/img/icon-ig.svg" alt="Instagram" />
          </a>
        </span>  
        <span class="item">
          <a data-track="substack" href="https://kahoneywell.substack.com/" target="_blank" @click="clickTrack">
            <img class="icon ss" src="../assets/img/icon-ss.svg" alt="Substack" />
          </a>
        </span> 
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    clickTrack($e) {
      var target = $e.target;
      if (target.getAttribute("target") == "_blank") {
        $e.preventDefault();
      }
      var href = target.getAttribute("href")
      var trackData = target.getAttribute("data-track")
      // console.log("click ", href, trackData)
      if (trackData) {
        this.$gtag.event('click_' + trackData, { method: 'Google' })
      }
      if (target.getAttribute("target") == "_blank") {
        window.open(href);
      }
    }
  },
}
</script>

<style lang="scss">
$break-to-stacked: 900px;

nav {
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  padding: 20px 20px;
  background-color: $black;
  color: $white;
  letter-spacing: .11rem;
  font-family: articulat-cf, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  text-transform: uppercase;

  /* animation */
  transform-origin: 50% 0;
  animation: nav-anim-in .5s cubic-bezier(0.455, 0.030, 0.000, 1.000) forwards;


  @media screen and (max-width: $break-to-stacked) {
    padding: 10px 20px;
  }
}

@keyframes nav-anim-in {
  0% {
    opacity: 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 100%;
    transform: scaleY(1);
  }
}

nav .max-w-wrap {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $break-to-stacked) {
    flex-direction: column;
  }

  .pages-wrap {
    @media screen and (max-width: $break-to-stacked) {
      margin-top: 10px;
    }
  }

  .page-link {
  margin: 0 30px;
  color: $white;

    @media screen and (max-width: $break-to-stacked) {
      margin: 0;
      margin-right: 20px;
    }
  
    a {
      position: relative;
      color: inherit;
      text-decoration: none;
      text-underline-offset: 6px;
      font-weight: inherit;
    }
    a:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: #ffffff;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    a:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  .list {
    display: inline-block;

    @media screen and (max-width: $break-to-stacked) {
      margin-top: 10px;
    }
  }

  .list .item {
    margin: 0 24px;
    transition: transform .2s linear;

    @media screen and (max-width: $break-to-stacked) {
      margin: 0;
      margin-right: 20px;
    }
  }
  .list .item img {
    transition: transform .2s ease;
  }
  .list .item:hover img {
    transform: translate3d(0, -2px, 0);
  }
  .list .item:last-child {
    margin-right: 0;
  }

  .list .item .icon {
    width: 20px;
  }
}

.content {
  margin: 0 20px;
  opacity: 0%;
  animation: .3s content-anim-in .3s ease-out forwards;
}
@keyframes content-anim-in {
  0% {
    opacity: 0%;
    transform: translate3d(0, -10px, 0);
  }
  100% {
    opacity: 100%;
    transform: translate3d(0, 0, 0);
  }
}
</style>