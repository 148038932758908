<template>
  <div class="page">
    <section>
      <div class="max-w-wrap">
        <h2>
          K.A. Honeywell writes&nbsp;fiction
        </h2>
        <p>
          Speculative, fantasy, fairy tales, weird, and surreal&mdash;that's what you'll find&nbsp;here.
        </p>
      </div>
    </section>
    <section>
      <div class="max-w-wrap">
        <div class="two-up books">
          <BookInfo size="small" title="Veiled Scarlet" descShort="Confined to a maze-like manor, the Countess’s daughter and her servant question whether the house is keeping them safe or hiding who they really&nbsp;are." folder="veiled-scarlet" cover="cover-667x1000.jpg" />
          <BookInfo size="small" title="Damn Wilds" descShort="Fast-paced, modern western-fantasy with magic-users and gunslingers stealing cars and killing monsters." folder="damn-wilds" cover="cover-1000x1500.jpg" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BookInfo from '@/components/BookInfo.vue';

export default {
  name: 'App',
  components: {
    BookInfo
    
}
}
</script>