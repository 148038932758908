<template>
<div class="book-wrap" :class=size>
  <div class="col cover">
    <a :href="`/publications/${folder}/`">
      <img :src="require(`@/assets/img/${folder}/${cover}`)" :alt="`${title} book cover`" />
    </a>
  </div>
  <div class="col info">
    <h3 v-if="size=='small'">
      {{ title }}
    </h3>
    <h2 v-if="size=='large'">
      {{ title }}
    </h2>
    <p v-if="descShort">
      {{descShort}}
    </p>
    <p v-if="descShort" class="cta">
      <a :href="`/publications/${folder}/`">Learn more</a>
    </p>
    <div v-if="descLong" v-html="descLong" class="paras-wrap"></div>
    <div class="blurbs-wrap" v-if="blurbs">
      <!-- eslint-disable-next-line -->
      <div v-for="blurb in blurbs">
          <p class="italic" v-html="blurb.quote" />
          <p class="source">
            <a :href="blurb.link">{{ blurb.source }}</a>
          </p>
      </div>
    </div>
    <div v-if="size=='large'" class="logos-wrap">
      <a :data-track="`${folder}-goodreads`" v-if="goodreads" :href="goodreads" target="_blank" @click="clickTrack">
        <img class="logo goodreads" :src="require(`@/assets/img/logo-goodreads.svg`)" alt="Goodreads logo" />
      </a>
      <a :data-track="`${folder}-kindle`" v-if="amazon" :href="amazon" target="_blank" @click="clickTrack">
        <img class="logo kindle" :src="require(`@/assets/img/logo-kindle.svg`)" alt="Amazon Kindle logo" />
      </a>
      <a :data-track="`${folder}-bn`" v-if="bn" :href="bn" target="_blank" @click="clickTrack">
        <img class="logo nook" :src="require(`@/assets/img/logo-bn-nook.svg`)" alt="Barnes & Noble Nook logo" />
      </a>
      <a :data-track="`${folder}-kobo`" v-if="kobo" :href="kobo" target="_blank" @click="clickTrack">
        <img class="logo kobo" :src="require(`@/assets/img/logo-rakuten-kobo.svg`)" alt="Rakuten Kobo logo" />
      </a>
      <a :data-track="`${folder}-apple`" v-if="apple" :href="apple" target="_blank" @click="clickTrack">
        <img class="logo apple" :src="require(`@/assets/img/logo-apple-books.svg`)" alt="Apple Books logo" />
      </a>
      <a :data-track="`${folder}-google`" v-if="google" :href="google" target="_blank" @click="clickTrack">
        <img class="logo kobo" :src="require(`@/assets/img/logo-google-play.png`)" alt="Google Play Books logo" />
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    size: String,
    title: String,
    descShort: String,
    descLong: String,
    folder: String,
    cover: String,
    goodreads: String,
    amazon: String,
    bn: String,
    kobo: String,
    apple: String,
    google: String,
    blurbs: Array,
  },
  methods: {
    clickTrack($e) {
      var target = $e.target;
      if (target.getAttribute("target") == "_blank") {
        $e.preventDefault();
      }
      var href = target.getAttribute("href")
      var trackData = target.getAttribute("data-track")
      // console.log("click ", href, trackData)
      if (trackData) {
        this.$gtag.event('click_' + trackData, { method: 'Google' })
      }
      if (target.getAttribute("target") == "_blank") {
        window.open(href);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.book-wrap {
  display: flex;

  // -------------------------------
  // SMALL
  &.small {
    gap: 50px;

    .col {
      width: 50%;
    }
  }

  @media screen and (max-width: 1024px) {
    &.small {
      gap: 20px;

      .col.cover {
        width: 25%;
      }

      .col.info {
        width: 75%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    &.small {
      flex-direction: column;

      .col.cover,
      .col.info {
        width: 100%;
      }
    }
  }

  // -------------------------------
  // LARGE
  &.large {
    gap: 90px;

    .col.cover {
      width: 30%;
    }

    .col.info {
      width: 70%;
    }
    
    .blurbs-wrap {
      margin-top: 35px;
      
    }

    .logos-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-top: 35px;
      
      .logo.goodreads {
        width: 120px;
      }

      .logo.nook {
        width: 120px;
      }

      .logo.kobo,
      .logo.google {
        width: 120px;
      }

      .logo.kindle,
      .logo.blurb {
        width: 80px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.large {
      gap: 20px;

      .col.cover {
        width: 25%;
      }

      .col.info {
        width: 75%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.large {
      flex-direction: column;

      .col.cover,
      .col.info {
        width: 100%;
      }
    }
  }
}
</style>