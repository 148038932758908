import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import { createMetaManager } from 'vue-meta'

const app = createApp(App);

app.use(router).use(createMetaManager())

app.use(VueGtag, {
  config: { 
    id: "G-RLH6FRECT8",
    // id: "G",
  },
}, router);

app.mount("#app");