<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | K.A. Honeywell` : `K.A. Honeywell` }}</template>
  </metainfo>
  <GlobalNav />
  <router-view />
  <GlobalFooter />
</template>

<script>
import GlobalNav from './components/GlobalNav.vue'
import GlobalFooter from './components/GlobalFooter.vue'
import { useMeta } from 'vue-meta'
//https://vue-meta.nuxtjs.org/api/#meta
//https://stackoverflow.com/questions/66228340/how-to-use-vue-3-meta-with-vue-js-3

export default {
  name: 'App',
  components: {
    GlobalNav,
    GlobalFooter
  },
  setup () {
    useMeta({
      title: '',
      meta: [
        {
          property: 'og:image',
          content: 'https://www.kahoneywell.com/social-share.jpg',
          vmid: 'og:image',
        },
      ]
    })
  },
  methods: {
  }
}
</script>