<template>
  <div class="page">
    <section>
      <div class="stars"></div>
      <div class="hero">
        <div class="content">
          <img class="title" :src="require(`@/assets/img/veiled-scarlet/title.png`)" alt="Veiled Scarlet" />
          <p>
            Confined to a maze-like manor, a Countess's daughter and her servant question whether the house is keeping them safe or hiding who they really&nbsp;are.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="max-w-wrap">
        <BookInfo size="large" title="Veiled Scarlet" descLong="<p>
              In the manor of the Countess, where hallways rearrange themselves and doors come and go, a servant is hiding from an arranged marriage, and the Countess's daughter is being hidden from the world. With her beauty said to eclipse her mother's, she's only allowed out at night with her face&nbsp;covered.
            </p>
            <p>
              As an unlikely relationship deepens between the Countess's daughter and her servant, they question whether the protective walls of the maze-like house are hiding them from the world they wish to escape, or are they hiding from who they really are? Guided by their hearts and the stars, can they find a path that will set them&nbsp;free?
            </p>" folder="veiled-scarlet" cover="cover-667x1000.jpg"
            goodreads="https://www.goodreads.com/book/show/75809981-veiled-scarlet"
            amazon="https://www.amazon.com/dp/B0BS4HNQSF?ref_=pe_3052080_276849420"
            bn="https://www.barnesandnoble.com/w?ean=2940185585481"
            kobo="https://www.kobo.com/us/en/ebook/veiled-scarlet"
            apple="https://books.apple.com/us/book/veiled-scarlet/id6445356443"
            google="https://play.google.com/store/books/details?id=uaKmEAAAQBAJ"
            :blurbs="blurbs" />
      </div>
    </section>
  </div>
</template>

<script>
import BookInfo from '@/components/BookInfo.vue';
import { useMeta } from 'vue-meta'

export default {
  name: 'DamnWilds',
  components: {
    BookInfo
  },
  setup () {
    useMeta({
      title: 'Veiled Scarlet',
      meta: [
        {
          property: 'og:image',
          content: 'https://www.kahoneywell.com/img/veiled-scarlet/social.png',
          vmid: 'og:image',
        },
        {
          property: 'keywords',
          content: 'speculative fiction, fairy tales, veiled scarlet, queer fiction, lesbian romance, sapphic books',
        }
      ],
    })
  },
  data() {
    return {
      blurbs: [
        {
          quote: '"...a scary, lovely fairytale, with magic and danger, and&nbsp;heroics."', source: 'The Lesbian Review', link: 'https://www.thelesbianreview.com/veiled-scarlet-k-a-honeywell/'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("https://use.typekit.net/yxx7amt.css");
* {
  box-sizing: border-box;
}
.page {
  --black: #0a0e09;
  --red: #e53028;
  --lt-blue: #303a4f;
  --yellow: #DBC89D;
  margin: 0;
  padding: 0;
  background-color: var(--black);
}

.stars,
.stars:after {
  position: fixed;
  z-index: 0;
  top: 40px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: auto 100vh ;
  background-position: center top;
}
.stars {
  background-color: var(--black);
  background-image: url("../../assets/img/veiled-scarlet/stars.png");
}
.stars:after {
  content: "";
  width: 200vw;
  background-image: url("../../assets/img/veiled-scarlet/stars2.png");
  animation: stars-loop 90s linear infinite;
}
@keyframes stars-loop {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  50% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 70vh;
}
.hero .content {
  position: relative;
  margin: auto;
}
.hero .content:before {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  content: "";
  background: radial-gradient(circle at center, rgba(10, 14, 9, 1) 0, rgba(10, 14, 9, .9) 36%, rgba(10, 14, 9, 0) 65%)
}
.hero .title {
  position: relative;
  display: block;
  width: 30vw;
  min-width: 300px;
}
.hero p .italic {
  font-style: italic;
}
.hero p {
  position: relative;
  margin-block: 50px;
  margin-inline: auto;
  width: 30vw;
  min-width: 300px;
  max-width: 400px;
  text-align: center;
  font-family: semplicitapro,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  letter-spacing: .1em;
  color: var(--yellow);
  /* text-shadow: -1px 0 10px var(--black), 0 1px 10px var(--black), 0 0 2px var(--black), 0 0 4px var(--black), 0 0 3px var(--black), 0px 1px 1px var(--black); */
  text-shadow: rgb(10, 14, 9) 5px 0px 0px, rgb(10, 14, 9) 4.900332927703857px 0.993346631526947px 0px, rgb(10, 14, 9) 4.605305194854736px 1.9470916986465454px 0px, rgb(10, 14, 9) 4.126677989959717px 2.8232123851776123px 0px, rgb(10, 14, 9) 3.4835336208343506px 3.586780548095703px 0px, rgb(10, 14, 9) 2.7015116214752197px 4.20735502243042px 0px, rgb(10, 14, 9) 1.81178879737854px 4.660195350646973px 0px, rgb(10, 14, 9) 0.8498356938362122px 4.927248477935791px 0px, rgb(10, 14, 9) -0.14599761366844177px 4.997868061065674px 0px, rgb(10, 14, 9) -1.1360105276107788px 4.869238376617432px 0px, rgb(10, 14, 9) -2.0807342529296875px 4.546487331390381px 0px, rgb(10, 14, 9) -2.9425055980682373px 4.042481899261475px 0px, rgb(10, 14, 9) -3.6869685649871826px 3.3773159980773926px 0px, rgb(10, 14, 9) -4.2844438552856445px 2.5775067806243896px 0px, rgb(10, 14, 9) -4.711111545562744px 1.6749407052993774px 0px, rgb(10, 14, 9) -4.949962615966797px 0.7056000232696533px 0px, rgb(10, 14, 9) -4.99147367477417px -0.29187071323394775px 0px, rgb(10, 14, 9) -4.833991050720215px -1.2777055501937866px 0px, rgb(10, 14, 9) -4.483792304992676px -2.212602138519287px 0px, rgb(10, 14, 9) -3.954838514328003px -3.0592894554138184px 0px, rgb(10, 14, 9) -3.2682180404663086px -3.78401255607605px 0px, rgb(10, 14, 9) -2.4513041973114014px -4.357878684997559px 0px, rgb(10, 14, 9) -1.536664366722107px -4.758010387420654px 0px, rgb(10, 14, 9) -0.5607626438140869px -4.968454837799072px 0px, rgb(10, 14, 9) 0.4374949038028717px -4.980823040008545px 0px, rgb(10, 14, 9) 1.4183108806610107px -4.794621467590332px 0px, rgb(10, 14, 9) 2.342583417892456px -4.417273044586182px 0px, rgb(10, 14, 9) 3.173464298248291px -3.8638224601745605px 0px, rgb(10, 14, 9) 3.8778293132781982px -3.1563332080841064px 0px, rgb(10, 14, 9) 4.427597522735596px -2.3230109214782715px 0px, rgb(10, 14, 9) 4.800851345062256px -1.3970774412155151px 0px, rgb(10, 14, 9) 4.982710361480713px -0.41544702649116516px 0px;
}
a {
  text-decoration: none;
}

</style>