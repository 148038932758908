<template>
  <div class="page">
    <section>
      <div class="max-w-wrap">
        <BookInfo size="large" title="Damn Wilds" descLong="<p>
              Clover has lived as the outcast wife of a shapeshifter for six years, and been miserable every second of it. The magic-hating townspeople harass her and threaten her life, but she’s accepted her fate.&nbsp;Mostly.
            </p>
            <p>
              When an accident gives Clover the chance to escape her exile, she seizes it, only to discover that it’s left her with a new, more terrifying problem&mdash;it’s trapped magic inside her. In a world that despises magic, that would have been enough, but this magic was designed to lure and now Clover is pursued by bloodthirsty animals called wilds. The only person she can rely on for help is Joss, the gunslinging wild hunter who caused the accident that upended Clover’s&nbsp;life.
            </p>
            <p>
              In a journey across the country, Clover and Joss search for a way to remove the magic while fending off wilds, dealing with Joss’s past coming back to bite him, and trying to survive among people who would kill to erase&nbsp;magic.
            </p>" folder="damn-wilds" cover="cover-1000x1500.jpg"
            goodreads="https://www.goodreads.com/book/show/58980071-damn-wilds"
            amazon="https://www.amazon.com/dp/B0B2N8KB7K/ref=cm_sw_r_tw_dp_HK4F6CNN3X3AKMCM7720"
            bn="https://www.barnesandnoble.com/w/damn-wilds-ka-honeywell/1140336795?ean=2940162302155"
            kobo="https://www.kobo.com/us/en/ebook/damn-wilds"
            apple="http://books.apple.com/us/book/id1591143651"
            google="https://play.google.com/store/books/details?id=CSdHEAAAQBAJ" />
      </div>
    </section>
  </div>
</template>

<script>
import BookInfo from '@/components/BookInfo.vue';
import { useMeta } from 'vue-meta'

export default {
  name: 'DamnWilds',
  components: {
    BookInfo
  },
  setup () {
    useMeta({
      title: 'Damn Wilds',
      meta: [
        {
          property: 'og:image',
          content: 'https://www.kahoneywell.com/img/damn-wilds/social.png',
          vmid: 'og:image',
        },
        {
          property: 'keywords',
          content: 'speculative fiction, fantasy books, urban fantasy books, low fantasy books, damn wilds, magical western, magic road trip fiction',
        }
      ],
    })
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-image: url('../../assets/img/damn-wilds/page-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
</style>