<template>
  <footer>
    <div class="max-w-wrap content">
      <span class="name">
        <a href="/">
          &copy; K.&thinsp;A. Honeywell
        </a>
      </span>
      <div class="pages-wrap">
      </div>
      <div class="list">
        <span class="item">
          <a data-track="substack" href="https://kahoneywell.substack.com/" target="_blank" @click="clickTrack">
            <span>Sign up for updates on new releases here: &nbsp;</span>
            <img class="icon ss" src="../assets/img/icon-ss.svg" alt="Substack" />
          </a>
        </span> 
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    clickTrack($e) {
      var target = $e.target;
      if (target.getAttribute("target") == "_blank") {
        $e.preventDefault();
      }
      var href = target.getAttribute("href")
      var trackData = target.getAttribute("data-track")
      // console.log("click ", href, trackData)
      if (trackData) {
        this.$gtag.event('click_' + trackData, { method: 'Google' })
      }
      if (target.getAttribute("target") == "_blank") {
        window.open(href);
      }
    }
  },
}
</script>

<style lang="scss">
$break-to-stacked: 900px;

footer {
  position: relative;
  padding: 20px 20px;
  background-color: $black;
  color: $white;
  letter-spacing: .11rem;
  font-family: articulat-cf, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: .8rem;
  text-transform: uppercase;

  @media screen and (max-width: $break-to-stacked) {
    padding: 10px 20px;
  }
}

footer .max-w-wrap {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $break-to-stacked) {
    flex-direction: column;
  }

  .pages-wrap {
    @media screen and (max-width: $break-to-stacked) {
      margin-top: 10px;
    }
  }

  .list {
    display: inline-block;

    @media screen and (max-width: $break-to-stacked) {
      margin-top: 10px;
    }
  }

  .list .item {
    display: flex;
    margin: 0 24px;
    transition: transform .2s linear;
    text-transform: none;

    @media screen and (max-width: $break-to-stacked) {
      margin: 0;
      margin-right: 20px;
    }
  }

  .list .item a {
    display: flex;
  }

  .list .item img {
    transition: transform .2s ease;
  }
  .list .item:hover img {
    transform: translate3d(0, -2px, 0);
  }
  .list .item:last-child {
    margin-right: 0;
  }

  .list .item .icon {
    width: 20px;
  }
}

.content {
  margin: 0 20px;
  opacity: 0%;
  animation: .3s content-anim-in .3s ease-out forwards;
}
@keyframes content-anim-in {
  0% {
    opacity: 0%;
    transform: translate3d(0, -10px, 0);
  }
  100% {
    opacity: 100%;
    transform: translate3d(0, 0, 0);
  }
}
</style>